import React from 'react'
import Layout from '../components/Layout'
import * as styles from "../styles/about.module.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faUsers } from "@fortawesome/free-solid-svg-icons"
import { faEye } from "@fortawesome/free-regular-svg-icons"

export default function about() {
    return (
        <div>
        <h1 className={styles.header}>About Us</h1>
        <Layout>
            <div className={styles.statement}>
                <p>Rival Solutions Incorporated is an IT staffing and consulting company headquartered in Morrisville, NC.</p>
                <p className={styles.secondPara}> We deliver consistent success based on leveraging the best IT resources to provide the optimal cost, service location and quality equation.</p>
                <p className={styles.secondPara}>We have a long-term association with renowned companies, which allows us to provide the best opportunities.</p>
            </div>
            <div className={styles.container}>
                <div>
                    <div>
                        <h1><FontAwesomeIcon icon={faEye} /></h1>
                    </div>
                    <h2>Vision</h2>
                    <p>Rival Solutions deliver consistent success based on leveraging the best IT resources to provide the optimal cost, service location and quality equation.</p>
                </div>
                <div>
                    <div>
                        <h1><FontAwesomeIcon icon={faUsers} /></h1>
                    </div>
                    <h2>Values</h2>
                    <p>Rival Solutions services are cost-effective and continuously integrate technological enhancements. Rival Solutions Inc. also offers training and placement services to enable a career for students and graduates in relevant industries. </p>
                </div>
                <div>
                    <div>
                        <h1><FontAwesomeIcon icon={faBullseye} /></h1>
                    </div>
                    <h2>Mission</h2>
                    <p>With over 23 years of experience in the IT industry, the founders of Rival Solutions Inc. bring technical skill, industry insight, business experience and thought leadership to all our service engagements.</p>
                </div>
                
            </div>
        </Layout>
        </div>
    )
}
